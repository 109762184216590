<template>
    <div class="skeleton-exp-card w-[224px] hbg-white-100 rounded-[10px] shadow-card relative overflow-hidden">
        <div class="w-full h-[148px] hbg-gray-500 animate-pulse"></div>
        <div class="p-2">
            <h1 class="h-5 hbg-gray-500 animate-pulse rounded-[6px] mt-3"></h1>
            <div class="flex h-4 mt-2">
                <h1 class="h-full hbg-gray-500 animate-pulse rounded-[6px] w-[160px]"></h1>
            </div>
        </div>
    </div>
</template>